<template>
    <div>
        <AccountHeadInfo />
        <div class="user-progress">
            <div class="container">
                <ul
                    class="
                        d-flex
                        flex-wrap
                        justify-content-center justify-content-md-start
                        py-3
                    "
                >
                    <li class="d-flex align-items-center mb-2 mb-md-0">
                        <span>CME Accredited ‏</span>
                        <div
                            class="
                                mx-2
                                py-1
                                px-2
                                bg-white
                                d-flex
                                align-items-center
                            "
                        >
                            <span v-if="userStats.cme_accredited && userStats.CME" class="font-weight-600 mx-1">
                                {{ userStats.cme_accredited || 0 }}/{{ userStats.CME }}
                            </span>
                            <span v-else class="font-weight-600 mx-1">
                                0
                            </span>
                            <span class="mx-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                >
                                    <path
                                        d="M12.5,3A9.5,9.5,0,1,0,22,12.5,9.5,9.5,0,0,0,12.5,3Zm.95,14.25h-1.9v-1.9h1.9Zm0-3.8h-1.9V7.75h1.9Z"
                                        transform="translate(-3 -3)"
                                        fill="#ffd064"
                                    />
                                </svg>
                            </span>
                        </div>
                    </li>
                    <li class="d-flex align-items-center mb-2 mb-md-0">
                        <span>Total CME / CPD‏</span>
                        <span v-if="userStats.total_accredited" class="font-weight-600 mx-2 py-1 px-2 bg-white">
                            {{ userStats.total_accredited }}
                        </span>
                        <span v-else class="font-weight-600 mx-2 py-1 px-2 bg-white">
                            0
                        </span>
                    </li>
                    <li class="d-flex align-items-center mb-2 mb-md-0">
                        <span>Modules</span>
                        <span v-if="userStats.modules" class="font-weight-600 mx-2 py-1 px-2 bg-white">
                            {{ userStats.modules }}
                        </span>
                        <span v-else class="font-weight-600 mx-2 py-1 px-2 bg-white">
                            0
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="container">
            <div class="my-account-container">
                <div class="d-flex flex-column flex-lg-row py-4">
                    <MyAccountSidebar />
                    <div class="my-account-content">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
        <TopFooterAds />
    </div>
</template>

<script>
import AccountHeadInfo from "@/components/MyAccount/AccountHeadInfo";
import MyAccountSidebar from "@/components/MyAccount/Sidebar";
import TopFooterAds from "@/components/TopFooterAds";

export default {
    name: "MyAccount",
    components: {
        AccountHeadInfo,
        MyAccountSidebar,
        TopFooterAds,
    },
    computed: {
        userStats() {
            return this.$store.getters['auth/userStats']
        }
    }
};
</script>

<style lang="scss" scoped>
.my-account-container {
    min-height: calc(100vh - (94px + 70px + 343px));
}
</style>